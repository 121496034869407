(function ($, window, document) {
  'use strict';
  $(function () {
    //##Variables
    var $body = $('body'),
      $window = $(window),
      $doc = $(document),
      $hamburger = $('.hamburger'),
      $mainNav = $('.mobile-nav'),
      $formTrigger = $('.form-trigger'),
      $headerForm = $('.header-form'),
      $regularRate = $('#regular-rate'),
      $alumniRate = $('#alumni-rate'),
      $teamDetails = $('#team-details'),
      $guestDetails = $('#guest-details'),
      $socialDetails = $('#social-details'),
      $fridayDetails = $('#friday-details'),
      $details = $('.tckt-slctr-tkt-details-sctn'),
      defaultEasing = [0.4, 0.0, 0.2, 1];
    //End Variables

    $regularRate.click(function () {
      $('#field_3_22 .tckt-slctr-tkt-details-sctn').toggleClass('opened');
      $(this).toggleClass('clicked');
      $('#field_3_22 .tckt-slctr-tkt-details-sctn').fadeToggle('fast');
    });

    $alumniRate.click(function () {
      $('#field_3_23 .tckt-slctr-tkt-details-sctn').toggleClass('opened');
      $(this).toggleClass('clicked');
      $('#field_3_23 .tckt-slctr-tkt-details-sctn').fadeToggle('fast');
    });

    $teamDetails.click(function () {
      $('#field_3_24 .tckt-slctr-tkt-details-sctn').toggleClass('opened');
      $(this).toggleClass('clicked');
      $('#field_3_24 .tckt-slctr-tkt-details-sctn').fadeToggle('fast');
    });
    $guestDetails.click(function () {
      $('#field_3_25 .tckt-slctr-tkt-details-sctn').toggleClass('opened');
      $(this).toggleClass('clicked');
      $('#field_3_25 .tckt-slctr-tkt-details-sctn').fadeToggle('fast');
    });
    $socialDetails.click(function () {
      $('#field_3_26 .tckt-slctr-tkt-details-sctn').toggleClass('opened');
      $(this).toggleClass('clicked');
      $('#field_3_26 .tckt-slctr-tkt-details-sctn').fadeToggle('fast');
    });
    $fridayDetails.click(function () {
      $('#field_3_27 .tckt-slctr-tkt-details-sctn').toggleClass('opened');
      $(this).toggleClass('clicked');
      $('#field_3_27 .tckt-slctr-tkt-details-sctn').fadeToggle('fast');
    });

    $hamburger.click(function () {
      $body.toggleClass('menuopened');
      $('.mobile-nav').toggleClass('menutoggled');
      $(this).toggleClass('toggled');
      $mainNav.fadeToggle('fast');
      jQuery('body').addClass('menu-opened');
    });

    $formTrigger.click(function () {
      $body.toggleClass('formopened');
      $('.header-form').toggleClass('menutoggled');
      $(this).toggleClass('toggled');
      $headerForm.slideToggle('fast');
    });
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 60) {
        $('header').addClass('is-scrolled');
      } else {
        $('header').removeClass('is-scrolled');
      }
    });

    // Function to handle the click event for the primary mobile menu
    function handleMenuClick() {
      $('#menu-primary-mobile-menu .menu-item-has-children').click(function (
        e
      ) {
        e.preventDefault(); // Prevent the default link behavior
        $(this).toggleClass('toggled');

        // Toggle the visibility of the submenu
        $(this).find('> .sub-menu').slideToggle();
      });

      // Add a click event listener to the links within the sub-menu
      $('#menu-primary-mobile-menu .menu-item-has-children .sub-menu a').click(
        function (e) {
          e.stopPropagation(); // Prevent the click event from propagating to the parent
        }
      );

      // Add click event listener for sub-menus that have sub-menus
      $('#menu-primary-mobile-menu .sub-menu .menu-item-has-children').click(
        function (e) {
          e.preventDefault(); // Prevent the default link behavior
          var $subMenu = $(this).find('> .sub-menu');
          if ($subMenu.length) {
            $(this).toggleClass('toggled');
            $subMenu.addClass('openthis');
          }
        }
      );

      // Add a click event listener to the links within the sub-sub-menu
      $(
        '#menu-primary-mobile-menu .sub-menu .menu-item-has-children .sub-menu a'
      ).click(function (e) {
        e.stopPropagation(); // Prevent the click event from propagating to the parent
      });

      // Code to handle the sub-menu of sub-menu separately
      $('#menu-primary-mobile-menu .sub-menu .menu-item-has-children').click(
        function (e) {
          e.stopPropagation(); // Prevent the click event from propagating to the parent
        }
      );

      $(
        '#menu-primary-mobile-menu .sub-menu .menu-item-has-children > a'
      ).click(function (e) {
        e.preventDefault(); // Prevent the default link behavior
        $(this).next('.sub-menu').slideToggle();
        $(this).parent().toggleClass('toggled');
      });
    }

    // Call the function to handle the menu click events
    handleMenuClick();

    $('.home-slider').owlCarousel({
      loop: true,
      margin: 0,
      autoplay: true,
      autoplayTimeout: 9000,
      animateOut: 'fadeOut',
      dots: true,
      touchDrag: true,
      mouseDrag: true,
      items: 1,
      nav: false,
      //   responsive: {
      //     0: {
      //       items: 1,
      //     },
      //     600: {
      //       items: 1,
      //     },
      //     1000: {
      //       items: 1,
      //     },
      //   },
    });
    $('.article-boxes-slider').owlCarousel({
      loop: true,
      margin: 3,
      dots: false,
      items: 3,
      nav: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    });
    $('.stories-slider').owlCarousel({
      loop: true,
      margin: 0,
      dots: true,
      items: 1,
      nav: false,
    });
  });
})(window.jQuery, window, document);
